import {Navigate, Route, Routes} from "react-router-dom"
import Main from "./pages/Main";
import { useEffect, useState, useCallback } from "react";
import toast from "react-hot-toast";
import { chat_id, getAvatar, getUserInfo } from "./api/service";
import Loader from "./componenets/loader";
import Friends from "./pages/Friends";

function App() {
  const [userInfo, setUserInfo] = useState(null);
  const [limits, setLimits] = useState({min: 0, max: 1000});
  const [actualLimit, setActualLimit] = useState(0);
  const [clicks, setClicks] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const checkDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobileDevices = /android|iphone|ipad|mobile/i;

    if (mobileDevices.test(userAgent)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };


  const getUser = async () => {
    try {
      const res = await getUserInfo();
      if (res.error) {
        return setUserInfo(false);
      } else {
        setUserInfo(res);
        setClicks(res.game_tokens);
        setActualLimit(res.game_handicap);
        setLimits({
          min: 0,
          max: Number(res.game_limit)
        });
      }
    } catch (e) {
      console.log(e);
      setUserInfo(false);
    }
  };

  // Fetch avatar with token if user info is available
  const fetchAvatar = useCallback(async (token) => {
    if (!userInfo || userInfo.avatar === true) return;
    const res = await getAvatar(token);
    if (res.error) return setUserInfo((prev) => ({ ...prev, avatar: true }));

    // Only update if the avatar has changed
    if (userInfo.avatar !== `data:${res.type};base64,${res.base64}` ) {
      setUserInfo((prev) => ({ ...prev, avatar: `data:${res.type};base64,${res.base64}` }));
    }
  }, [userInfo]);

  // Set up the interval to update actual limit
  useEffect(() => {
    if (!userInfo) return;

    let timer = setInterval(() => {
      if (Number(actualLimit) >= limits.max) return;
      setActualLimit((prev) => Number(prev) + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [userInfo, actualLimit, limits.max]);

  // Fetch avatar when the token changes
  useEffect(() => {
    if (userInfo?.token) {
      fetchAvatar(userInfo.token);
    }
  }, [userInfo?.token, fetchAvatar]);

  // Setup Telegram WebApp and fetch user info on mount
  useEffect(() => {
    window?.Telegram?.WebApp?.disableVerticalSwipes();
    window?.Telegram?.WebApp?.expand();
    window?.Telegram?.WebApp?.setHeaderColor("#000000");
    window?.Telegram?.WebApp?.setBackgroundColor("#000000");
    getUser();
    checkDevice();
  }, []);


  
  if (userInfo === null || !userInfo.avatar) return <Loader />;


  if (!isMobile && chat_id !== 6540281438 && chat_id !== 3238601) {
    return <div className="use__mobile">Please use a mobile device to access this application.</div>;
  }

  console.log(userInfo)
  return (
    <div className="app">
      <Routes>
        <Route
          path="/"
          element={<Main limits={limits} isMobile={isMobile} actualLimit={actualLimit} setActualLimit={setActualLimit} userInfo={userInfo} setUserInfo={setUserInfo} setClicks={setClicks} clicks={clicks}/>}
        />
        <Route
          path="/friends"
          element={<Friends limits={limits} isMobile={isMobile} actualLimit={actualLimit} setActualLimit={setActualLimit} userInfo={userInfo} setUserInfo={setUserInfo} setClicks={setClicks} clicks={clicks}/>}
        />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
