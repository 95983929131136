import toast from "react-hot-toast"
import TabBar from "../componenets/TabBar"
import { useEffect, useState } from "react";
import { postClick } from "../api/service";
import splitNumbers from "../helpers/splitNumbers";
import getRatingOfUser from "../helpers/getRatingOfUser";


const Main = ({userInfo, setClicks, clicks, setActualLimit, actualLimit, limits, isMobile}) => {
    const [stop, setStop] = useState(false);
    const [maxClicks, setMaxClicks] = useState([]);
    const maxClicksPerSecond = 40;

    const [activeChiken, setActiveChiken] = useState(1);
    const [lastChangeTime, setLastChangeTime] = useState(0);
    const changeDuration = 4000; 

    useEffect(() => {
        const now = Date.now();

        // Check if enough time has passed since the last image change
        if (now - lastChangeTime >= changeDuration) {
            if (clicks > 0) {
                if (clicks % 50 === 0 && activeChiken !== 2) {
                    setActiveChiken(2);
                    setLastChangeTime(now);
                } else if (clicks % 25 === 0 && activeChiken !== 3) {
                    setActiveChiken(3);
                    setLastChangeTime(now);
                } else if (activeChiken !== 1) {
                    setActiveChiken(1);
                    setLastChangeTime(now);
                }
            }
        }
    }, [clicks, activeChiken, lastChangeTime]);

    const clickHandler = async (e) => {
        if((Number(actualLimit) <= limits.min) && !stop) {
            setStop(true)
            toast.error("Wait for recovery");
            setTimeout(() => {
                setStop(false);
            }, 2000);
            return
        }
        const now = Date.now();
        const oneSecondAgo = now - 1000;

        const recentClicks = maxClicks.filter(
        (clickTime) => clickTime > oneSecondAgo
        );

        if (recentClicks.length < maxClicksPerSecond) {
            setMaxClicks([...recentClicks, now]);
            await setClicks((prev) => Number(prev) + 1);
            await setActualLimit((prev) => Number(prev) - 1);
            const res = await postClick(userInfo.token)
            if(res.success === false) return //toast.error(res.message)
        } else {
            setStop(true);
            setTimeout(() => {
                setStop(false);
            }, 2000);
            return toast.error("Too many clicks");
        }
    };


    const clickHandlerAnim = (e) => {
        const clickX = e.clientX;
        const clickY = e.clientY;

        const imgElement = document.createElement('img');

        /*if (userInfo.perClick === 1) {
        imgElement.src = "/click1.PNG";
        } else if (userInfo.perClick === 2) {
        imgElement.src = "/click2.PNG";
        } else if (userInfo.perClick === 3) {
        imgElement.src = "/click3.PNG";
        } else if (userInfo.perClick === 4) {
        imgElement.src = "/click4.PNG";
        } else {
        imgElement.src = "/click1.PNG";
        }*/

        imgElement.classList.add('click-animation');
        imgElement.style.top = `${clickY}px`;
        imgElement.style.left = `${clickX}px`;

        document.body.appendChild(imgElement);

        imgElement.classList.add('clicked');

        setTimeout(() => {
        imgElement.remove();
        }, 1000);
    };

    const handleClick = (e) => {
        clickHandler(e);
        //clickHandlerAnim(e);
        const rect = e.target.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const clickY = e.clientY - rect.top;
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;

        const deltaX = clickX - centerX;
        const deltaY = clickY - centerY;

        const tiltX = -(deltaY / centerY) * 20;
        const tiltY = (deltaX / centerX) * 20;

        e.target.style.transition = "transform 0.3s ease";
        e.target.style.transform = `perspective(200px) rotateX(${tiltX}deg) rotateY(${tiltY}deg)`;

        setTimeout(() => {
        e.target.style.transition = "transform 0.1s ease";
        e.target.style.transform =
            "perspective(200px) rotateX(0deg) rotateY(0deg)";
        }, 100);
    };


    return (
        <div className="main__page">
            <div>
                <div className="main__tools">
                    <div className="shop__btn" onClick={() => toast.error("Coming soon")}>Shop</div>
                    <div className="tools">
                        <div className="tool" onClick={() => toast.error("Coming soon")}>
                            <img src="/img/speed.png" className="tool__img"/>
                            <div className="tool__title">SPEED UP</div>
                        </div>
                        <div className="tool" onClick={() => toast.error("Coming soon")}>
                            <img src="/img/games.png" className="tool__img"/>
                            <div className="tool__title">GAMES</div>
                        </div>
                        <div className="tool" onClick={() => toast.error("Coming soon")}>
                            <img src="/img/lab.png" className="tool__img"/>
                            <div className="tool__title">EGG LAB</div>
                        </div>
                        <div className="tool" onClick={() => toast.error("Coming soon")}>
                            <img src="/img/settings.png" className="tool__img"/>
                            <div className="tool__title">SETTINGS</div>
                        </div>
                    </div>
                </div>
                <img src="/img/divider.png" className="divider" />
                <div className="banner__user">
                    <div className="userInfo__block">
                        <div className="avatar">
                            {userInfo?.avatar && userInfo?.avatar !== true ? <img src={userInfo?.avatar} className="avatar__img"/> : <div className="avatar__char">{String((userInfo.first_name ? userInfo.first_name : userInfo.username.replace("@", "")) || "U").charAt(0).toUpperCase()}</div>}
                        </div>
                        <div className="username">
                            {(userInfo.first_name ? userInfo.first_name : userInfo.username.replace("@", "")) || "User"}
                        </div>
                    </div>
                    <div className="userInfo__rating">
                        <div className="userInfo__rating__title">{getRatingOfUser(Number(clicks)).title}</div>
                        <img src="/img/cub.png" className="userInfo__rating__cub"/>
                        <div className="progress__banner">
                            <div className="bar__banner">
                                <div className="bar__active" style={{width: `${getRatingOfUser(Number(clicks)).progress || 0}%`}}></div>
                                <div className="bar__line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main__tap">
                <div className="balance__title">EGGS <img src="/img/egg.png"/></div>
                <div className="balance">{splitNumbers(Number(clicks))}</div>
                <img src="/img/divider.png" className="divider" style={{width:"60%"}}/>
                <img src={`/img/chicken${activeChiken}.png`} className="chicken" onTouchEnd={(e) => {
                    if(stop ) return
                    handleClick(e);
                }} onClick={(e) => {
                    if(!isMobile) {
                        if(stop ) return
                        handleClick(e);
                    }
                }}/>
            </div>

            <div>
            <div className="progress" style={{marginTop:"45px"}}>
                <div>
                    <div className="bar">
                        <div className="min">{limits.min}</div>
                        <div className="max">{actualLimit}</div>
                        {/*<div className="bar__actual">{actualLimit}</div>*/}
                        <div className="bar__active" style={{width: `${(Number(actualLimit) / Number(limits.max) * 100) || 0}%`}}></div>
                        <div className="bar__line"></div>
                    </div>
                </div>
                
                
            </div>
            <TabBar activeTab={1}/>
            </div>
        </div>
    )
}

export default Main