const baseURL = "https://game.easycrypto.space/api_game/api.php"
export const chat_id = window.Telegram.WebApp.initDataUnsafe?.user?.id
//export const chat_id = 3238601 

const sendFetch = (method, url = "", body = {}, token = "") => {
    if(method === "GET") {
        return fetch(baseURL + url, {
            method: method,
            headers: {

            }
        }).then(res => res.json())
    } else {
        return fetch(baseURL + url, {
            method: method,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }).then(res => res.json())
    }
}


export const getUserInfo = () => {
    return sendFetch("POST", "", {
        "action": "authenticate",
        "chat_id": chat_id
    })
}

export const postClick = (token) => {
    return sendFetch("POST", "", {
        "action": "addGameTokens",
        "chat_id": chat_id
    }, token)
}


export const getAvatar = (token) => {
    return sendFetch("POST", "", {
        "action": "getUserProfilePhotos",
        "chat_id": chat_id
    }, token)
}

export const getFriends = (token) => {
    return sendFetch("POST", "", {
        "action": "getReferrals",
        "chat_id": chat_id
    }, token)
}