

const Loader = () => {
    return (
        <div className="loader__block">
            <img src="/load.svg" />
        </div>
    )
}

export default Loader