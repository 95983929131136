export const HomeIcon = ({ active }) => {
    const color = active ? "#FF9000" : "#AAAAAA"; // Меняем цвет для активной вкладки
    return (
        <svg className="tab__img" width="63" height="64" viewBox="0 0 63 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_11_489)">
                <path d="M29.4943 4.37869C30.4658 3.47517 31.9702 3.47517 32.9417 4.37869L61.8977 31.3069C62.5802 31.9415 62.6189 33.0093 61.9842 33.6917C61.3496 34.3742 60.2818 34.4129 59.5994 33.7782L31.218 7.38443L2.83663 33.7782C2.15417 34.4129 1.08643 34.3742 0.451769 33.6917C-0.182895 33.0093 -0.144152 31.9415 0.538305 31.3069L29.4943 4.37869Z" fill={color}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M32.9225 16.0712C31.9575 15.1913 30.4815 15.1899 29.5149 16.0681L5.93865 37.4886V63.1796H27.1293V46.9591H35.6315V63.1796H57.2463V38.2505L32.9225 16.0712ZM9.31357 38.9822L31.216 19.0825L53.8714 39.7404V59.8047H39.0064V43.5842H23.7544V59.8047H9.31357V38.9822Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_11_489">
                    <rect width="62.436" height="62.436" fill="white" transform="translate(0 0.796997)"/>
                </clipPath>
            </defs>
        </svg>
    );
};