

export const FrinedIcons = ({active}) => {

    const color = (!active ? "#939393" : "#FF9000")
    return (
        <svg className="tab__img" width="63" height="64" viewBox="0 0 63 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_19_117)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9481 2.97498C16.8626 2.97498 11.1188 8.68642 11.1188 15.7318C11.1188 22.7773 16.8626 28.4887 23.9481 28.4887C31.0335 28.4887 36.7774 22.7773 36.7774 15.7318C36.7774 8.68642 31.0335 2.97498 23.9481 2.97498ZM14.5399 15.7318C14.5399 10.5652 18.7521 6.3768 23.9481 6.3768C29.1441 6.3768 33.3563 10.5652 33.3563 15.7318C33.3563 20.8985 29.1441 25.0869 23.9481 25.0869C18.7521 25.0869 14.5399 20.8985 14.5399 15.7318Z" fill={color}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M48.3357 18.4048C43.6121 18.4048 39.7829 22.2124 39.7829 26.9093C39.7829 31.6063 43.6121 35.4139 48.3357 35.4139C53.0594 35.4139 56.8886 31.6063 56.8886 26.9093C56.8886 22.2124 53.0594 18.4048 48.3357 18.4048ZM43.204 26.9093C43.204 24.0912 45.5016 21.8066 48.3357 21.8066C51.1699 21.8066 53.4675 24.0912 53.4675 26.9093C53.4675 29.7275 51.1699 32.0121 48.3357 32.0121C45.5016 32.0121 43.204 29.7275 43.204 26.9093Z" fill={color}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 56.8796C0 43.9829 10.3054 33.467 23.0928 33.467C35.8802 33.467 46.1856 43.9829 46.1856 56.8796V63.233H0V56.8796ZM23.0928 36.8688C12.2621 36.8688 3.42115 45.7942 3.42115 56.8796V59.8312H42.7644V56.8796C42.7644 45.7942 33.9235 36.8688 23.0928 36.8688Z" fill={color}/>
            <path d="M48.0183 44.5229C47.8013 44.5229 47.5852 44.5293 47.3701 44.542C46.83 43.426 46.2149 42.3531 45.5312 41.3301C46.3396 41.1926 47.1699 41.1211 48.0183 41.1211C52.6754 41.1211 56.3516 43.3153 58.8048 46.7628C61.2237 50.162 62.436 54.7343 62.436 59.631V63.233H59.0149V59.631C59.0149 55.2444 57.9224 51.4122 56.0121 48.7277C54.1362 46.0915 51.4588 44.5229 48.0183 44.5229Z" fill={color}/>
            </g>
            <defs>
            <clipPath id="clip0_19_117">
            <rect width="62.436" height="62.436" fill="white" transform="translate(0 0.796997)"/>
            </clipPath>
            </defs>
        </svg>
    )
}