export default (clicks) => {
    let title = "Bronze";
    let progressBarData = 0;
    
    if(Number(clicks) < 1000000) {
        title = "Bronze"
        progressBarData = (Number(clicks) / 1000000) * 100
    } else if (Number(clicks) >= 1000000 && Number(clicks) < 10000000) {
        title = "Silver"
        progressBarData = Number(clicks) / 10000000 * 100
    } else if (Number(clicks) >= 10000000) {
        title = "Gold"
        progressBarData = 100
    }

    return {
        "title": title,
        "progress": progressBarData
    }
}