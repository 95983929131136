import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { FrinedIcons } from "./icons/FriendsIcon";
import { HomeIcon } from "./icons/HomeIcon";

const TabBar = ({ activeTab }) => {
    const nav = useNavigate();
    const tabs = [
        {
            id: 1,
            title: "HOME",
            to: "/",
            icon: <HomeIcon active={activeTab === 1} />,
        },
        {
            id: 2,
            title: "LEADER",
            src: "/img/LEADER.png",
        },
        {
            id: 3,
            title: "MINE",
            src: "/img/MINE.png",
        },
        {
            id: 4,
            title: "EARN",
            src: "/img/EARN.png",
        },
        {
            id: 5,
            title: "FRIENDS",
            to: "/friends",
            icon: <FrinedIcons active={activeTab === 5} />,
        },
    ];

    return (
        <div>
            <img src="/img/divider.png" className="divider" alt="divider" />
            <div className="main__tabbar">
                {tabs.map((tab) => (
                    <div
                        key={tab.id}
                        className={`tab ${activeTab === tab.id ? "active" : ""}`}
                        onClick={() => {
                            if (tab.to) return nav(tab.to);
                            toast.error("Coming soon");
                        }}
                    >
                        {tab.icon ? tab.icon : <img src={tab.src} className="tab__img" alt={tab.title} />}
                        <div className="tab__title">{tab.title}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TabBar;
