import toast from "react-hot-toast"
import TabBar from "../componenets/TabBar"
import splitNumbers from "../helpers/splitNumbers"
import { useEffect, useState } from "react"
import Loader from "../componenets/loader"
import { getFriends } from "../api/service"


const Friends = ({userInfo}) => {
    const [friends, setFriends] = useState(null)

    const getFriendsFunc = async () => {
        try {
          const res = await getFriends(userInfo.token);
          console.log(res)
          if (res.error) {
            return toast.error(res.error)
          } else {
            setFriends(res.referrals);
          }
        } catch (e) {
          console.log(e);
        }
      };

      useEffect(() => {
        getFriendsFunc();
      }, []);

    if(friends === null) return <Loader />
    return (
        <div className="friends__page">
            <div style={{paddingBottom:"15px"}}>
                <div className="invite__title">Invite friends<br /> and get more EGGS</div>
                <img src="/img/friend1.jpeg" className="invite__img"/>
                <div className="invite__desc">Tap on the button to invite your friends</div>

                {friends && <div className="friends__list">
                    {friends.map(friend => {
                        return (
                        <div className="friend">
                            <div className="userInfo__block">
                                {/*<div className="avatar">
                                    {userInfo?.avatar && userInfo?.avatar !== true ? <img src={userInfo?.avatar} className="avatar__img"/> : <div className="avatar__char">{String((userInfo.first_name ? userInfo.first_name : userInfo.username.replace("@", "")) || "U").charAt(0).toUpperCase()}</div>}
                                </div>*/}
                                <div>
                                    <div className="username">
                                    {friend?.first_name ? friend?.first_name : "User"} {friend?.last_name ? friend?.last_name : ""}
                                    </div>
                                    <div className="username__friends">
                                        Refferal level: {friend?.level}
                                    </div>
                                </div>
                            </div>
                            <div className="balance__friends">
                                {splitNumbers(Number(friend.game_tokens))} <img src="/img/egg.png"/>
                            </div>
                        </div>
                        )
                    })}
                    
                </div>}
            </div>
            <div>
                <div className="invite__button" onClick={() => {
                    window?.Telegram?.WebApp?.openTelegramLink(`https://t.me/share/url?url=${`https://t.me/birdshouse_bot?start=${userInfo?.chat_id}`}&text=Who let the EGGS out?`)
                    //return toast.success("Copied!")
                }}>
                    Invite friends
                </div>
                <TabBar activeTab={5}/>
            </div>
            
        </div>
    )
}

export default Friends